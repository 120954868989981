import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VListItem,{staticClass:"primary"},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"headline text-left white--text"},[_vm._v("Active Timesheets")])],1)],1),[_c(VDataTable,{staticClass:"pt-4",attrs:{"headers":_vm.headers,"items":_vm.pendingTimesheet,"items-per-page":5,"customSort":_vm.timesheetSort},scopedSlots:_vm._u([{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.enterSubmitTimesheet(item)}}},on),[_c(VIcon,{staticClass:"clickable",attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Submit Timesheet")])])]}},{key:"item.EmployeeName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.EmployeeName))]}},{key:"item.MonthName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.MonthName))]}},{key:"item.StatusID",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.StatusID))]}},{key:"item.Description",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.Description))]}},{key:"item.RejectReason",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.RejectReason))]}}])})]],2)],1)],1),_c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VListItem,{staticClass:"primary"},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"headline text-left white--text"},[_vm._v("Timesheet Approvals")])],1)],1),[_c(VDataTable,{staticClass:"pt-4",attrs:{"headers":_vm.headers,"items":_vm.pendingApproval,"items-per-page":5,"customSort":_vm.timesheetSort},scopedSlots:_vm._u([{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.approveTimesheet(item)}}},on),[_c(VIcon,{staticClass:"clickable",attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Approve Timesheet")])])]}},{key:"item.EmployeeName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.EmployeeName))]}},{key:"item.MonthName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.MonthName))]}},{key:"item.StatusID",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.StatusID))]}},{key:"item.Description",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.Description))]}},{key:"item.RejectReason",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.RejectReason))]}}])})]],2)],1)],1),_c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VListItem,{staticClass:"primary"},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"headline text-left white--text"},[_vm._v("Completed Timesheets")])],1)],1),[_c(VDataTable,{staticClass:"pt-4",attrs:{"headers":_vm.headers2,"items":_vm.completedTimesheet,"items-per-page":5,"customSort":_vm.timesheetSort,"sort-by":"MonthName"},scopedSlots:_vm._u([{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.approveTimesheet(item)}}},on),[_c(VIcon,{staticClass:"clickable",attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Approve Timesheet")])])]}},{key:"item.EmployeeName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.EmployeeName))]}},{key:"item.MonthName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.MonthName))]}},{key:"item.StatusID",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.StatusID))]}}])})]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }