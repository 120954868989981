<template>
  <v-container fluid>
    <!-- ACTIVE TIMESHEETS -->
    <v-row>
      <v-col sm="12">
        <v-card class="mx-auto">
          <v-list-item class="primary">
            <v-list-item-content>
              <v-list-item-title class="headline text-left white--text"
                >Active Timesheets</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <template>
            <v-data-table
              :headers="headers"
              :items="pendingTimesheet"
              :items-per-page="5"
              :customSort="timesheetSort"
              class="pt-4"
            >
              <template v-slot:item.view="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-btn
                      color="primary"
                      v-on="on"
                      icon
                      @click="enterSubmitTimesheet(item)"
                    >
                      <v-icon class="clickable" color="primary"
                        >mdi-pencil</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Submit Timesheet</span>
                </v-tooltip>
              </template>
              <template v-slot:item.EmployeeName="{item}">{{
                item.EmployeeName
              }}</template>
              <template v-slot:item.MonthName="{item}">{{
                item.MonthName
              }}</template>
              <template v-slot:item.StatusID="{item}">{{
                item.StatusID
              }}</template>
              <template v-slot:item.Description="{item}">{{
                item.Description
              }}</template>
              <template v-slot:item.RejectReason="{item}">{{
                item.RejectReason
              }}</template>
            </v-data-table>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <!-- TIMESHEET APPROVALS -->
    <v-row>
      <v-col sm="12">
        <v-card class="mx-auto">
          <v-list-item class="primary">
            <v-list-item-content>
              <v-list-item-title class="headline text-left white--text"
                >Timesheet Approvals</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <template>
            <v-data-table
              :headers="headers"
              :items="pendingApproval"
              :items-per-page="5"
              :customSort="timesheetSort"
              class="pt-4"
            >
              <!-- //note: not approved :customSort="timesheetSort" -->
              <template v-slot:item.view="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-btn
                      color="primary"
                      v-on="on"
                      icon
                      @click="approveTimesheet(item)"
                    >
                      <v-icon class="clickable" color="primary"
                        >mdi-pencil</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Approve Timesheet</span>
                </v-tooltip>
              </template>
              <template v-slot:item.EmployeeName="{item}">{{
                item.EmployeeName
              }}</template>
              <template v-slot:item.MonthName="{item}">{{
                item.MonthName
              }}</template>
              <template v-slot:item.StatusID="{item}">{{
                item.StatusID
              }}</template>
              <template v-slot:item.Description="{item}">{{
                item.Description
              }}</template>
              <template v-slot:item.RejectReason="{item}">{{
                item.RejectReason
              }}</template>
            </v-data-table>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <!-- COMPLETED TIMESHEETS -->
    <v-row>
      <v-col sm="12">
        <v-card class="mx-auto">
          <v-list-item class="primary">
            <v-list-item-content>
              <v-list-item-title class="headline text-left white--text"
                >Completed Timesheets</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <template>
            <v-data-table
              :headers="headers2"
              :items="completedTimesheet"
              :items-per-page="5"
              :customSort="timesheetSort"
              sort-by="MonthName"
              class="pt-4"
            >
              <template v-slot:item.view="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-btn
                      color="primary"
                      v-on="on"
                      icon
                      @click="approveTimesheet(item)"
                    >
                      <v-icon class="clickable" color="primary"
                        >mdi-pencil</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Approve Timesheet</span>
                </v-tooltip>
              </template>
              <template v-slot:item.EmployeeName="{item}">{{
                item.EmployeeName
              }}</template>
              <template v-slot:item.MonthName="{item}">{{
                item.MonthName
              }}</template>
              <template v-slot:item.StatusID="{item}">{{
                item.StatusID
              }}</template>
            </v-data-table>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- SCRIPTS -->
<script>
import {debounce, isEqual, filter, sortBy, range} from 'lodash'
import {get, sync, commit, call} from 'vuex-pathify'
import {authMethods, authComputed} from '@state/helpers'
import TimeSheet from '@classes/TimeSheet'
import TimeSheetStatus from '@classes/TimeSheetStatus'

export default {
  data: () => ({
    search: '',
    timeSheet: [],
    headers: [
      {
        text: '',
        align: 'center',
        value: 'view',
        sortable: false,
      },
      {
        text: 'Employee Name',
        align: 'center',
        sortable: 'true',
        value: 'EmployeeName',
      },
      {
        text: 'Month',
        align: 'center',
        sortable: 'true',
        value: 'MonthName',
      },
      // {
      //   text: 'Status',
      //   align: 'center',
      //   sortable: 'true',
      //   value: 'StatusID',
      // },
      {
        text: 'Status',
        align: 'center',
        sortable: 'true',
        value: 'Description',
      },
      {
        text: 'Rejection Note',
        align: 'center',
        sortable: 'true',
        value: 'RejectReason',
      },
    ],
    headers2: [
      {
        text: '',
        align: 'center',
        value: 'view',
      },
      {
        text: 'Employee Name',
        align: 'center',
        sortable: 'true',
        value: 'EmployeeName',
      },
      {
        text: 'Month',
        align: 'center',
        sortable: 'true',
        value: 'MonthName',
      },
      {
        text: 'Status',
        align: 'center',
        sortable: 'true',
        value: 'Description',
      },
    ],
    months: [
      'jan',
      'feb',
      'mar',
      'apr',
      'may',
      'jun',
      'jul',
      'aug',
      'sep',
      'oct',
      'nov',
      'dec',
    ],
  }),
  computed: {
    ...authComputed,
    pendingTimesheet() {
      var pending = _.filter(this.timeSheet, function (v) {
        return v.StatusID == 0 || v.StatusID == 5
      })
      return pending
    },
    pendingApproval() {
      var pending = _.filter(this.timeSheet, function (v) {
        return v.StatusID != 0 && v.StatusID != 5 && v.StatusID != 4
      })
      return pending
    },
    completedTimesheet() {
      var completed = _.filter(this.timeSheet, function (v) {
        return v.StatusID == 4
      })
      return completed
    },
  },
  methods: {
    //note: this was requested, but not approved, so it it not enabled
    timesheetSort(items, index, isDesc) {
      if (index.length > 0) {
        index = index[0]
      }
      if (isDesc.length > 0) {
        isDesc = isDesc[0]
      }
      if (items && items.length > 0) {
        if (index == 'MonthName') {
          items.sort((a, b) => {
            var aIndex = this.months.findIndex((m) => {
              return a.MonthName.toLowerCase().startsWith(m)
            })
            var bIndex = this.months.findIndex((m) => {
              return b.MonthName.toLowerCase().startsWith(m)
            })
            var aYear = a.MonthName.match(/([\d])+/g)[0]
            var bYear = b.MonthName.match(/([\d])+/g)[0]
            if (isDesc) {
              if (aYear != bYear) {
                return bYear - aYear
              }
              return bIndex - aIndex
            } else {
              if (aYear != bYear) {
                return aYear - bYear
              }
              return aIndex - bIndex
            }
          })
        } else {
          items.sort((a, b) => {
            if (isDesc) {
              return b[index] < a[index] ? -1 : 1
            } else {
              return a[index] < b[index] ? -1 : 1
            }
          })
        }
      }
      return items
    },
    handleError: call('errors/handleError'),
    getApprovalsByUser() {
      if (this.currentUser) {
        let url = `/TimeSheet/ApprovalsByUser/${this.currentUser.ID}`
        this.$axios.get(url).then(
          (res) => {
            this.timeSheet = res.data
          },
          (error) => {
            this.handleError(error)
            this.error = error
            console.error(error)
          }
        )
      }
    },
    enterSubmitTimesheet(item) {
      this.$router.push({
        name: 'timesheetSubmit',
        params: {id: item.ID},
      })
    },
    approveTimesheet(item) {
      this.$router.push({
        name: 'timesheetApprove',
        params: {id: item.ID},
      })
    },
  },
  created() {
    this.getApprovalsByUser()
  },
  watch: {
    currentUser: {
      handler(nval) {
        if (nval) {
          this.getApprovalsByUser()
        }
      },
    },
  },
}
</script>

<!-- STYLES -->
<style lang="scss">
//@import '@design';
</style>

